
import AvatarImage from "@/components/AvatarImage.vue";
import { ServerResponse } from "@/services/exploreService";
import { ServersModule } from "@/store/modules/servers";
import { PopoutsModule } from "@/store/modules/popouts";
import CustomButton from "@/components/CustomButton.vue";
import { joinServerById } from "@/services/serverService";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ExploreServerTemplate",
  components: { AvatarImage, CustomButton },
  props: {
    data: {
      type: Object as PropType<ServerResponse>,
      required: true,
    },
  },
  data() {
    return {
      joining: false,
      hovering: false,
      tweCrown: process.env.VUE_APP_TWEMOJI_LOCATION + "1f451.png",
    };
  },
  computed: {
    isJoined(): any {
      return ServersModule.servers[this.data.server.server_id];
    },
    bannerURL(): any {
      if (!this.data.server.banner) return null;
      return (
        process.env.VUE_APP_NERTIVIA_CDN +
        this.data.server.banner +
        (!this.hovering ? "?type=webp" : "")
      );
    },
  },
  methods: {
    showCreatorProfile() {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: this.data.creator.id },
      });
    },
    visitClicked() {
      this.$router.push(
        `/app/servers/${this.isJoined.server_id}/${this.isJoined.default_channel_id}`
      );
    },
    joinClicked() {
      PopoutsModule.ShowPopout({
        id: "captcha-popout",
        component: "CaptchaPopout",
        data: {
          callback: this.captchaVerified,
        },
      });
    },
    captchaVerified(token: string) {
      this.joining = true;
      joinServerById(this.data.server.server_id, {
        socketID: this.$socket.id,
        token,
      }).finally(() => {
        this.joining = false;
      });
    },
  },
});
