
import { ServerResponse, getServers } from "@/services/exploreService";
import ServerTemplate from "./ExploreServerTemplate.vue";
import CustomDropDown from "@/components/CustomDropDown.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ExploreArea",
  components: { ServerTemplate, CustomDropDown, LoadingScreen },
  data() {
    return {
      sortParam: "",
      filterParam: "verified=true",
      data: null as ServerResponse[] | null,
      filters: [
        { name: "Verified Servers", param: "verified=true" },
        { name: "All Servers", param: "" },
      ],
      sorts: [
        { name: "Most Users", param: "" },
        { name: "Least Users", param: "most_users=false" },
        { name: "Alphabetical", param: "alphabetical=true" },
        { name: "Date Added", param: "date_added=true" },
      ],
    };
  },
  computed: {
    buildParam(): any {
      if (!this.sortParam && this.filterParam) {
        return `?${this.filterParam}`;
      }
      if (this.sortParam && !this.filterParam) {
        return `?${this.sortParam}`;
      }
      return `?${this.sortParam}&${this.filterParam}`;
    },
  },
  watch: {
    buildParam: {
      handler: "onFilterChange",
    },
  },
  async mounted() {
    this.data = await getServers();
  },
  methods: {
    async onFilterChange() {
      this.data = null;
      this.data = await getServers(this.buildParam);
    },
  },
});
