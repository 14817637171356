
import {
  getPublicThemes,
  PublicThemeResponse,
} from "@/services/exploreService";
import ThemeTemplate from "./ExploreThemeTemplate.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ExploreThemes",
  components: { ThemeTemplate, LoadingScreen },
  data() {
    return {
      data: [] as PublicThemeResponse[],
      appliedThemeID: localStorage["themeID"] || null,
      filters: [
        { name: "Compatible", param: "compatible" },
        { name: "All", param: undefined },
      ],
      selectedFilter: "compatible",
    };
  },
  watch: {
    selectedFilter: {
      handler: "onFilterChange",
    },
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.data = [];
      this.data = (await getPublicThemes(
        undefined,
        undefined,
        this.$lastUIBreakingVersion
      )) as any;
    },
    liked(index: number) {
      const newData = this.data?.[index];
      if (!newData) return;
      if (newData.likes === undefined) return;
      newData.liked = true;
      newData.likes += 1;
      this.data[index] = newData;
    },
    unliked(index: number) {
      const newData = this.data?.[index];
      if (!newData) return;
      if (newData.likes === undefined) return;
      newData.liked = false;
      newData.likes -= 1;
      this.data[index] = newData;
    },
    async onFilterChange() {
      this.loadData();
    },
  },
});
