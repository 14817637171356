
import {
  applyPublicTheme,
  likeTheme,
  PublicThemeResponse,
  unlikeTheme,
} from "@/services/exploreService";
import { PopoutsModule } from "@/store/modules/popouts";
import CustomButton from "@/components/CustomButton.vue";
import { applyTheme, unapplyTheme } from "@/utils/CSSTheme";
import { createTheme } from "@/services/themeService";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ExploreThemeTemplate",
  components: { CustomButton },
  props: {
    data: {
      type: Object as PropType<PublicThemeResponse>,
      required: true,
    },
    appliedThemeID: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      hovering: false,
      cloning: false,
      tweCrown: process.env.VUE_APP_TWEMOJI_LOCATION + "1f451.png",
      likeRequest: false,
    };
  },
  computed: {
    screenshotURL(): any {
      if (!this.data.screenshot) return null;
      return process.env.VUE_APP_NERTIVIA_CDN + this.data.screenshot;
    },
  },
  methods: {
    showCreatorProfile() {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: this.data.creator.id },
      });
    },
    async applyTheme() {
      applyTheme(this.data.id);
      this.$emit("applied");
    },
    async likeTheme() {
      if (this.likeRequest) return;
      this.likeRequest = true;
      if (this.data.liked) {
        unlikeTheme(this.data.id)
          .then(() => {
            this.$emit("unliked");
          })
          .finally(() => {
            this.likeRequest = false;
          });
        return;
      }
      likeTheme(this.data.id)
        .then(() => {
          this.$emit("liked");
        })
        .finally(() => {
          this.likeRequest = false;
        });
    },
    unapplyTheme() {
      unapplyTheme();
      this.$emit("unapplied");
    },
    async cloneTheme() {
      if (this.cloning) return;
      this.cloning = true;
      const theme = await applyPublicTheme(this.data.id);
      const name = theme.theme.name;
      const css = theme.css;
      createTheme({
        css,
        name,
        client_version: this.$lastUIBreakingVersion,
      }).finally(() => {
        this.cloning = false;
      });
    },
  },
});
